import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

/**
 * StatusLog component.
 *
 * @returns {*}
 * @constructor
 */
function HistoryTab(props) {
    const {record} = props;
    const [history, setHistory] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/transaction/${record.id}/history`);
        setHistory(response.data);
        setLoading(false);
    };

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => setTimeout(() => {
            if (window.hljs) {
                window.hljs.highlightAll()
            }
        }, 500));
    }, [record]);

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'columns__1'}>
                    <div>
                        <h3 className={'text__bold'}>History</h3>
                        <p className={'text__muted'}>
                            These are the changes made to this transaction. Featured was added on April 1st, 2025.
                        </p>
                    </div>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table>
                    <colgroup>
                        <col width="40%"/>
                        <col/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>Change</b></TableCell>
                            <TableCell align={'right'}><b>Date</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Skeleton width={'70%'} style={{marginLeft: 'auto'}}/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && history.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={2} align={'center'}>
                                    <div className={'text__muted'}>
                                        No history available.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {history.map((historyEntry, index) => (
                            <TableRow key={`history-${index}`}>
                                <TableCell dangerouslySetInnerHTML={{__html: `<pre><code class="language-json">${historyEntry.change}</code></pre>`}}/>
                                <TableCell align={'right'}>
                                    {Formatter.date(historyEntry.created_at, 'MM/DD/YYYY hh:mm A', true)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default HistoryTab;