import React, {useEffect, useRef, useState} from "react";

import API from "../Generic/API";
import TabContainer from "../Components/TabContainer";
import AllTransactions from "./Transactions/AllTransactions";
import CompletedTransactions from "./Transactions/CompletedTransactions";
import CancelledTransactions from "./Transactions/CancelledTransactions";
import InProgressTransactions from "./Transactions/InProgressTransactions";
import SearchClient from "./Transactions/SearchClient";
import SearchServer from "./Transactions/SearchServer";

import moment from "moment";

import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {MobileDatePicker} from "@mui/x-date-pickers";

/**
 * Transactions component.
 *
 * @returns {*}
 * @constructor
 */
function Transactions() {
    const drawerWidth = 280;
    const [companies, setCompanies] = useState([]);
    // this is used to differentiate between the client and server table types
    const [tableType, setTableType] = useState(localStorage.getItem('transactions__tableType') || 'server');

    const defaultDates = {
        // this checks certain things about the dates to make sure it's a viable search
        search_date_acceptable: false,

        // inspection date filters.
        search_by_date: false,
        inspection_start_date: null,
        inspection_end_date: null,
    };
    const storedDates = JSON.parse(localStorage.getItem('transactions__dateSearch') || '{}');
    const [dateSearch, setDateSearch] = useState({
        ...defaultDates,
        ...storedDates
    });

    // if the stored date search has start and end dates we need to make them into a moment object
    if (dateSearch.inspection_start_date !== null) {
        dateSearch.inspection_start_date = moment(dateSearch.inspection_start_date, 'YYYY-MM-DD');
    }

    if (dateSearch.inspection_end_date !== null) {
        dateSearch.inspection_end_date = moment(dateSearch.inspection_end_date, 'YYYY-MM-DD');
    }

    const [searchText, setSearchText] = useState('');
    const [searches, setSearches] = useState(0);
    const [filterUpdate, setFilterUpdate] = useState(null);
    const [dateCompareError, setDateCompareError] = useState(false);

    // Searching and filtering storage.
    const storedFilters = JSON.parse(localStorage.getItem('transactions__filter') || '{}');

    const defaultFilters = {
        status: '',
        company: '',

        // Process status filters.
        pre_close: false,
        not_started: false,
        pre_collections: false,
        collections: false,
        invoice_sent: false,
        payment_sent: false,
        guardian_funded: false,
        removed_from_pac: false,
        removed_from_isn: false,

        // Title confirmed filters.
        tc_yes: false,
        tc_no: false,

        // Funding filters.
        funding_queued_yes: false,
        funding_queued_no: false,

        // Collections filters.
        cc_funded: false,
        check_funded: false,
    };

    if (dateSearch.search_date_acceptable) {
        defaultFilters.inspection_start_date = dateSearch.inspection_start_date.format('YYYY-MM-DD');;
        defaultFilters.inspection_end_date = dateSearch.inspection_end_date.format('YYYY-MM-DD');;
    }

    const [processStatuses, setProcessStatuses] = useState([]);
    const [processFilters, setProcessFilters] = useState([]);
    const [tab, setTab] = useState(localStorage.getItem('transactions__tab') || 'in_progress');
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        ...defaultFilters,
        ...storedFilters
    });

    /**
     * Loads all available process statuses via the API.
     *
     * @returns {Promise<void>}
     */
    const getProcessStatuses = async () => {
        const response = await API.send(`pay-at-close/transactions/process-status`);

        setProcessStatuses(response.process_status);
        setProcessFilters(response.filter_process_status);
    };


    /**
     * Loads all available companies via the API.
     *
     * @returns {Promise<void>}
     */
    const getCompanies = async () => {
        const response = await API.send(`pay-at-close/reporting/companies`);

        if (Array.isArray(response)) {
            setCompanies(
                response.map(company => {
                    return {
                        label: company.name
                    };
                })
            );
        }
    };

    /**
     * Changes the table type
     *
     * @param event
     */
    const handleTableTypeChange = (event) => {
        const tableTypeValue = !!event.target.checked ? 'client' : 'server';

        localStorage.setItem('transactions__tableType', tableTypeValue);
        setTableType(tableTypeValue);
        setFilterUpdate(null);
    };

    /**
     * Changes the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = (event, updated) => {
        setFilterUpdate(null);
        localStorage.setItem('transactions__tab', updated);
        setTab(updated);
    };


    /**
     * Updates a date selection
     *
     * @param key
     * @param value
     */
    const handleDateUpdate = (key, value) => {
        setDateCompareError(false);
        let searchDateAcceptable = true;
        let oldSearchDateAcceptable = dateSearch.search_date_acceptable;
        let oldInspectionStartDate = dateSearch.inspection_start_date;
        let oldInspectionEndDate = dateSearch.inspection_end_date;
        let startDateFormatted, endDateFormatted, updatedFilter;

        const updated = {
            ...dateSearch,
            [key]: value
        };

        if (updated.inspection_start_date instanceof Object) {
            startDateFormatted = updated.inspection_start_date.format('YYYY-MM-DD');
        }

        if (updated.inspection_end_date instanceof Object) {
            endDateFormatted = updated.inspection_end_date.format('YYYY-MM-DD');
        }

        if (!updated.search_by_date) {
            searchDateAcceptable = false;
        } else if (updated.inspection_start_date === null || updated.inspection_end_date === null) {
            searchDateAcceptable = false;
        } else if (startDateFormatted > endDateFormatted) {
            searchDateAcceptable = false;
            setDateCompareError(true);
        }

        updated.search_date_acceptable = searchDateAcceptable;
        localStorage.setItem('transactions__dateSearch', JSON.stringify(updated));
        setDateSearch(updated);

        // figure out what we need to send to the filters so it's sent to the backend if necessary
        let dateChanged = searchDateAcceptable && (oldInspectionStartDate !== updated.inspection_start_date || oldInspectionEndDate !== updated.inspection_end_date);

        if (oldSearchDateAcceptable === true && !searchDateAcceptable) {
            delete filter.inspection_start_date;
            delete filter.inspection_end_date;
            delete filter.search_by_date;
            delete filter.search_date_acceptable;
            updatedFilter = {...filter};
            setFilterUpdate(true);
            setFilter(updatedFilter);
        } else if ((oldSearchDateAcceptable === false && searchDateAcceptable) || dateChanged) {
            updatedFilter = {
                ...filter,
                ['inspection_start_date']: startDateFormatted,
                ['inspection_end_date']: endDateFormatted
            };
            setFilterUpdate(true);
            setFilter(updatedFilter);
        }
    };


    /**
     * Updates a filter selection.
     *
     * @param key
     * @param value
     */
    const handleFilterUpdate = (key, value) => {
        let updated = {
            ...filter,
            [key]: value
        };

        // we don't want to store the start and end date filters here
        const startDate = updated.inspection_start_date;
        const endDate = updated.inspection_end_date;
        const searchByDate = updated.search_by_date;

        delete updated.inspection_start_date;
        delete updated.inspection_end_date;
        delete updated.search_by_date;

        localStorage.setItem('transactions__filter', JSON.stringify(updated));

        updated = {
            ...updated,
            inspection_start_date: startDate,
            inspection_end_date: endDate,
            search_by_date: searchByDate
        };

        setFilterUpdate(true);
        setFilter(updated);
    }


    /**
     * Load companies on initial page visit.
     */
    useEffect(() => {
        getProcessStatuses().then(() => true);
        getCompanies().then(() => true);
    }, []);

    return (
        <div className={'drawer__page'}>
            <Drawer
                className={'drawer'}
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                }}
            >
                <div className={'columns__1'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tableType === 'client'}
                                onChange={handleTableTypeChange}
                            />
                        }
                        label="Download All Transactions To Search (Slower)"
                    />
                    <Divider/>
                    <Autocomplete
                        disablePortal
                        options={companies}
                        value={filter.company}
                        onChange={(event, value) => handleFilterUpdate('company', value)}
                        renderInput={(params) => <TextField {...params} label="Filter by Company"/>}
                    />
                    <Divider/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!dateSearch.search_by_date}
                                onChange={(event) => handleDateUpdate('search_by_date', !!event.target.checked)}
                            />
                        }
                        label="Filter By Inspection Date"
                    />
                    {!!dateSearch.search_by_date ?
                    <>
                        <MobileDatePicker
                            label="Start Date"
                            inputFormat="YYYY-MM-DD"
                            value={dateSearch.inspection_start_date}
                            onChange={(value) => handleDateUpdate('inspection_start_date', value)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <MobileDatePicker
                            label="End Date"
                            inputFormat="YYYY-MM-DD"
                            value={dateSearch.inspection_end_date}
                            onChange={(value) => handleDateUpdate('inspection_end_date', value)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        {dateCompareError ? <Alert severity={'error'}>Please enter an end date that is after the start date.</Alert> : ''}
                    </>
                    : ''}
                    <Divider/>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Process Status</FormLabel>
                        <FormGroup>
                            {Object.keys(processFilters).map((processFilterName, processFilterIndex) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter[processFilters[processFilterName].name]}
                                            onChange={(event) => handleFilterUpdate(processFilters[processFilterName].name, !!event.target.checked)}
                                        />
                                    }
                                    label={processFilters[processFilterName].value}
                                    key={`process-status-${processFilterIndex}`}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <Divider/>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Closing Information</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.closing_information_yes}
                                        onChange={(event) => handleFilterUpdate('closing_information_yes', !!event.target.checked)}
                                    />
                                }
                                label="Yes"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.closing_information_no}
                                        onChange={(event) => handleFilterUpdate('closing_information_no', !!event.target.checked)}
                                    />
                                }
                                label="No"
                            />
                        </FormGroup>
                    </FormControl>
                    <Divider/>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Collections Status</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.cc_funded}
                                        onChange={(event) => handleFilterUpdate('cc_funded', !!event.target.checked)}
                                    />
                                }
                                label="CC Funded"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.check_funded}
                                        onChange={(event) => handleFilterUpdate('check_funded', !!event.target.checked)}
                                    />
                                }
                                label="Check Funded"
                            />
                        </FormGroup>
                    </FormControl>
                    <Divider/>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Title Confirmed</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.tc_yes}
                                        onChange={(event) => handleFilterUpdate('tc_yes', !!event.target.checked)}
                                    />
                                }
                                label="Yes"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.tc_no}
                                        onChange={(event) => handleFilterUpdate('tc_no', !!event.target.checked)}
                                    />
                                }
                                label="No"
                            />
                        </FormGroup>
                    </FormControl>
                    <Divider/>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Queued for Funding</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.funding_queued_yes}
                                        onChange={(event) => handleFilterUpdate('funding_queued_yes', !!event.target.checked)}
                                    />
                                }
                                label="Yes"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.funding_queued_no}
                                        onChange={(event) => handleFilterUpdate('funding_queued_no', !!event.target.checked)}
                                    />
                                }
                                label="No"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </Drawer>
            <div className={'columns__1'}>
                <Paper className={'table__block'}>
                    <div className={'columns__1 gap__0'}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}} className={'transactions__heading'}>
                            <div className={'d-flex__justify'}>
                                <Tabs value={tab} onChange={handleTabChange}>
                                    <Tab label="In Progress" value="in_progress"/>
                                    <Tab label="Completed" value="completed"/>
                                    <Tab label="Cancelled" value="cancelled"/>
                                    <Tab label="All" value="all"/>
                                </Tabs>
                                {tableType === 'server' ?
                                <SearchServer
                                    searches={searches}
                                    setSearches={setSearches}
                                    setSearch={setSearch}
                                    processStatuses={processStatuses}
                                /> :
                                <SearchClient
                                    setSearchText={setSearchText}
                                    drawerWidth={drawerWidth}
                                    processStatuses={processStatuses}
                                />
                                }
                            </div>
                        </Box>

                        <div className={'hide__scroll'}>
                            <TabContainer value={tab} target={'in_progress'}>
                                <InProgressTransactions
                                    search={search}
                                    searchText={searchText}
                                    searches={searches}
                                    filter={filter}
                                    tableType={tableType}
                                    filterUpdate={filterUpdate}
                                    setFilterUpdate={setFilterUpdate}
                                    processStatuses={processStatuses}
                                />
                            </TabContainer>
                            <TabContainer value={tab} target={'completed'}>
                                <CompletedTransactions
                                    search={search}
                                    searchText={searchText}
                                    searches={searches}
                                    filter={filter}
                                    tableType={tableType}
                                    filterUpdate={filterUpdate}
                                    setFilterUpdate={setFilterUpdate}
                                    processStatuses={processStatuses}
                                />
                            </TabContainer>
                            <TabContainer value={tab} target={'cancelled'}>
                                <CancelledTransactions
                                    search={search}
                                    searchText={searchText}
                                    searches={searches}
                                    filter={filter}
                                    tableType={tableType}
                                    filterUpdate={filterUpdate}
                                    setFilterUpdate={setFilterUpdate}
                                    processStatuses={processStatuses}
                                />
                            </TabContainer>
                            <TabContainer value={tab} target={'all'}>
                                <AllTransactions
                                    search={search}
                                    searchText={searchText}
                                    searches={searches}
                                    filter={filter}
                                    tableType={tableType}
                                    filterUpdate={filterUpdate}
                                    setFilterUpdate={setFilterUpdate}
                                    processStatuses={processStatuses}
                                />
                            </TabContainer>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

export default Transactions;
