import React from "react";

import Formatter from "../../Style/Formatter";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Skeleton from "@mui/material/Skeleton";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import TableContainer from "@mui/material/TableContainer";

/**
 * JobsBlock component.
 *
 * @returns {*}
 * @constructor
 */
function JobsBlock(props) {
    const {record, jobs, isLoading} = props;

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'d-flex__justify'}>
                    <h3 className={'text__bold'}>Alerts</h3>

                    <WorkHistoryIcon/>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table stickyHeader>
                    <colgroup>
                        <col width="40%"/>
                        <col/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>Alert</b></TableCell>
                            <TableCell><b>Date</b></TableCell>
                            <TableCell><b>Processed?</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && !jobs.length && (
                            <TableRow>
                                <TableCell colSpan={3} align={'center'}>
                                    <div className={'text__muted'}>
                                        No jobs are scheduled or processed for this order.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {jobs.map((job, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <b className={'white-space__pre'}>{job.name}</b>
                                </TableCell>
                                <TableCell>
                                    <div className={'white-space__pre'}>
                                        {Formatter.date(job.job_date, 'MM/DD/YYYY hh:mm A')}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {job.finished === 'yes' ? 'Yes' : 'No'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default JobsBlock;