import React, {useState} from "react";

import Fees from "./Accounts/Fees";
import Results from "./Accounts/Results";
import TabContainer from "../Components/TabContainer";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

/**
 * Accounts component.
 *
 * @returns {*}
 * @constructor
 */
function Accounts() {
    const [tab, setTab] = useState('accounts');

    /**
     * Updates the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = (event, updated) => {
        setTab(updated);
    };

    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify tabbed__heading'}>
                <h3 className={'hide__mobile'}>Account Management</h3>

                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Accounts" value="accounts"/>
                    <Tab label="Fees By State" value="feesbystate"/>
                </Tabs>
            </div>

            <TabContainer value={tab} target={'accounts'}>
                <div className={'columns__1'}>
                    <Results/>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'feesbystate'}>
                <div className={'columns__1'}>
                    <Fees/>
                </div>
            </TabContainer>
        </div>
    );
}

export default Accounts;
