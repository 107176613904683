import React from "react";
import Moment from 'moment'

/**
 * Formatter class.
 */
class Formatter {
    /**
     * Formats an input date string.
     *
     * @param input
     * @param format
     * @returns {string}
     */
    static date(input, format = 'L', utc = false) {
        if (!input) {
            return '';
        }

        const moment = Number.isInteger(input) ? Moment.unix(input) : Moment(input);

        // we need to convert dates stored in utc over
        // all dates stored in MySQL database are stored in UTC
        // all dates stored in DynamoDB are stored in Eastern US
        if (utc) {
            moment.utc(true);
            moment.local();
        }

        return moment.format(format);
    }


    /**
     * Returns a formatted date/time string.
     *
     * @param input
     * @returns {string}
     */
    static dateTime(input) {
        return Formatter.date(input, 'MM/DD/YYYY hh:mm A')
    }


    /**
     * Formats an input string as a clickable email.
     *
     * @param input
     * @returns {*}
     */
    static email(input) {
        return input ? (
            <a href={`mailto: ${input}`}>
                {input}
            </a>
        ) : null
    }


    /**
     * Formats an input string as a clickable telephone link.
     *
     * @param input
     * @returns {*}
     */
    static phone(input) {
        return input ? (
            <a href={`tel: ${input}`}>
                {input}
            </a>
        ) : null
    }


    /**
     * Formats a boolean as a Yes / No string.
     *
     * @param input
     * @returns {string}
     */
    static boolean(input) {
        return input ? 'Yes' : 'No';
    }


    /**
     * Formats a number as a currency string.
     *
     * @param input
     * @returns {string}
     */
    static currency(input) {
        if (!input) {
            input = 0;
        }

        if (typeof input === 'string' || input instanceof String) {
            input = parseFloat(input);
        }

        return `$${input.toFixed(2)}`;
    }
}

export default Formatter;