import React, {useEffect, useRef, useState} from "react";

import API from "../../Generic/API";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import MUIDataTable from "mui-datatables";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";

/**
 * Home component.
 *
 * @returns {*}
 * @constructor
 */
function Fees() {

    const [availableStates, setAvailableStates] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveErrorMessage, setSaveErrorMessage] = useState('');

    /**
     * Load all states and approved states on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    const getResults = async () => {
        setLoading(true);

        const response = await API.send(`pay-at-close/charge-fees-by-state/status`);

        if (response?.data?.states?.length > 0) {
            setAvailableStates(response.data.states);
        } else {
            setAvailableStates([]);
        }

        if (response?.data?.all_states?.length > 0) {
            setAllStates(response.data.all_states);
        } else {
            setAllStates([]);
        }

        setLoading(false);
    };

    const handleStateChoice = (choice) => {
        if (choice?.name?.length === 0) {
            return;
        }

        setAvailableStates((prevState) => {
            const currentStates = [...prevState];
            currentStates.push(choice);

            return currentStates;
        });
    };

    const handleStateRemoval = (choice) => {
        setAvailableStates((prevState) => {
            let currentStates = [...prevState];
            currentStates = currentStates.filter((elem) => elem.name !== choice.name);

            return currentStates;
        });
    };

    const handleSave = async () => {
        setSaving(true);
        setSaveErrorMessage('');

        const formattedStates = [];
        availableStates.forEach((value, index) => {
            formattedStates.push({
                'state_id': value.id
            });
        });

        const response = await API.send(
            `pay-at-close/charge-fees-by-state/save`,
            'POST',
            {
                states: JSON.stringify(formattedStates)
            }
        );

        if (response.status === 'error') {
            setSaveErrorMessage(response.message);
        }

        setSaving(false);
    };

    return (
        <div className={'columns__1'}>
            <Paper className={'table__block'}>
                <div className={'block__heading block__heading--bordered'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>FlexFund Fee State Eligibility</h3>
                            <p className={'text__muted'}>This will allow you to change the currently charged states for FlexFund.</p>
                        </div>
                    </div>
                </div>
                <div>
                { saveErrorMessage !== '' ?
                    <Alert severity={'error'}>{saveErrorMessage}</Alert>
                    : (saving ?
                    <Alert severity={'info'}>Currently saving your changes. Please wait.</Alert> :
                    <Alert severity={'success'}>Your changes are up to date.</Alert>
                    )}
                </div>

                <div className={'columns__2'}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Autocomplete
                                                disablePortal
                                                options={allStates}
                                                getOptionLabel={(state) => state.name}
                                                getOptionDisabled={(option) => availableStates.some(availableState => availableState.id === option.id)}
                                                onChange={(event, value) => handleStateChoice(value)}
                                                renderInput={(params) => <TextField {...params} style={{minWidth: '350px'}} label="Choose State To Add to the Approved List"/>}
                                            />
                                        </Grid>
                                        <Grid item style={{marginTop: '0.5em'}}>
                                            <Button variant="contained" disabled={saving} onClick={handleSave}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {loading && <LinearProgress/>}
                    <TableContainer component={'div'} className={'transactions__list'}>
                        <MUIDataTable
                            className={'table__grid'}
                            data={availableStates.sort((a, b) => a.name.localeCompare(b.name))}
                            options={
                                {
                                    selectableRows: "none",
                                    responsive: 'standard',
                                    count: availableStates.length,
                                    rowsPerPage: 100,
                                    rowsPerPageOptions: [50, 100, 250, 500, 1000],
                                    filter: false,
                                    search: false,
                                    print: false,
                                    download: true,
                                    viewColumns: true,
                                    customToolbar: null,
                                    fixedHeader: true,
                                    tableBodyHeight: 'calc(100vh - 230px)',
                                    customRowRender: (data, i) => {
                                        const record = availableStates[i];

                                        if (!record) {
                                            return;
                                        }

                                        return (
                                            <TableRow key={i}>
                                                <TableCell>
                                                {record.name}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        component="label"
                                                        role={undefined}
                                                        variant="contained"
                                                        tabIndex={-1}
                                                        onClick={() => handleStateRemoval(record)}
                                                        sx={{marginTop:'7px', marginRight:'10px'}}
                                                        >
                                                        Remove
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            )
                                        }
                                    }
                                }
                                columns={[
                                    {
                                        name: "state",
                                        label: "State",
                                        options: {
                                            sort: true,
                                            download: false,
                                        }
                                    }, {
                                        name: "",
                                        label: "Actions",
                                        options: {
                                            sort: false,
                                            download: false
                                        }
                                    }
                                ]}
                            />
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

export default Fees;
