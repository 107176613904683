import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Skeleton from "@mui/material/Skeleton";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import PostAddIcon from '@mui/icons-material/PostAdd';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";

/**
 * NotesBlock component.
 *
 * @returns {*}
 * @constructor
 */
function NotesBlock(props) {
    const {record, notes, setNotes} = props;
    const [note, setNote] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isFormOpen, setFormOpen] = useState(false);

    /**
     * Loads any results via the API.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/transaction/${record.id}/notes`);
        setNotes(response);
        setLoading(false);
    };


    /**
     * Performs a save via the API.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setLoading(true);

        await API.send(
            `pay-at-close/transaction/${record.id}/note`,
            'POST',
            {
                notes: note
            }
        );

        // Refresh the component.
        setNote('');
        setFormOpen(false);
        getResults();
    };


    /**
     * Reveals the note form.
     */
    const handleFormOpen = () => {
        setFormOpen(true);
    };


    /**
     * Hides the note form.
     */
    const handleFormClose = () => {
        setFormOpen(false);
    };


    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        if (!record.id) {
            return;
        }

        setLoading(false);

        setTimeout(() => {
            if (window.hljs) {
                window.hljs.highlightAll()
            }
        }, 500);
    }, [record]);

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'d-flex__justify'}>
                    <h3 className={'text__bold'}>Notes</h3>

                    <IconButton onClick={handleFormOpen}>
                        <PostAddIcon/>
                    </IconButton>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table stickyHeader>
                    <colgroup>
                        <col width="40%"/>
                        <col/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>User</b></TableCell>
                            <TableCell><b>Note</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton/>
                                    <Skeleton/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && !notes.length && (
                            <TableRow>
                                <TableCell colSpan={2} align={'center'}>
                                    <div className={'text__muted'}>
                                        No notes available.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {notes.map((note, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <b className={'white-space__pre'}>{note.created_by}</b>
                                    <div className={'white-space__pre'}>
                                        {Formatter.date(note.created_at, 'MM/DD/YYYY hh:mm A')}
                                    </div>
                                </TableCell>
                                <TableCell dangerouslySetInnerHTML={{__html: note.note}}/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={isFormOpen} onClose={handleFormClose}>
                <DialogTitle>
                    <h3>Add Note</h3>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{width: 360}}
                        rows={6}
                        multiline
                        onChange={(event) => setNote(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFormClose} disabled={isLoading}>Cancel</Button>
                    <Button onClick={doSave} disabled={isLoading}>Save</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default NotesBlock;